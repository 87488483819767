// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <h1>
//           D3j Music & Technology
//         </h1>
//         <p>
//           Harmonizing Innovation, Amplifying Dreams
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;

import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="App-title">D3j Music & Technology (DM&T)</h1>
        <p className="App-description">Harmonizing Music Innovation</p>
        <a
          className="App-link"
          href="https://www.livetrackz.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          LiveTrackz
        </a>
      </header>
    </div>
  );
}

export default App;
